import { Routes, Route, useLocation, useSearchParams } from "react-router-dom";
import { Header, TabNames } from "./components/Header/Header";
import { Footer } from "./components/Footer/Footer";
import { Contact } from "./components/Contact/Contact";
import { Home } from "./components/Home/Home";
import { Product } from "./components/Product/Product";
import { About } from "./components/About/About";
import { useEffect, useState } from "react";
import { MenuItemProps } from "semantic-ui-react";
import { Mobile } from "./components/MobileF/Mobile";
import ArrowUpwardOutlinedIcon from '@mui/icons-material/ArrowUpwardOutlined';
import "./App.css";
import { Demo } from "./components/Demo/Demo";
import { SchoolLife } from "./components/SchoolLife/SchoolLife";
import { Gallery } from "./components/Gallery/Gallery";


export const App = () => {
  const [activeItem, setActiveItem] = useState<TabNames>(TabNames.HOME);
  const location = useLocation()

  const [navBg, setNavBg] = useState(true);


  const changeNavBg = () => {
      window.scrollY >= 150 ? setNavBg(true) : setNavBg(false);
     }
   
     useEffect(() => {
       window.addEventListener('scroll', changeNavBg);
       return () => {
         window.removeEventListener('scroll', changeNavBg);
       }
     }, []);
     const scrollToTop = () => {
      window.scrollTo(0,0);
     }

 
  useEffect(() => {
    const pathname1 = location.pathname.split("?")[0];  
    console.log(pathname1,'ssssss');
          
    switch(pathname1) {
        case "/academics":
            setActiveItem(TabNames.PRODUCTS);
            break;
            case "/mobile":
              setActiveItem(TabNames.MOBILE);
              break;
        case "/contact":
            setActiveItem(TabNames.CONTACT);
            break;
            case "/demo":
            setActiveItem(TabNames.DEMO);
            break;
            case "/schoollife":
            setActiveItem(TabNames.SCHOOLLIFE);
            break;
            case "/gallery":
              setActiveItem(TabNames.GALLERY);
              break;
        case "/about-us":
            setActiveItem(TabNames.ABOUT_US);
            break;
        default:
            setActiveItem(TabNames.HOME);
            break;
    }        
  }, [location.pathname])

  const handleItemClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, data: MenuItemProps) => {
    console.log(data.name,"namesssss");
    if (data.name) {
        setActiveItem(data.name as TabNames);
      
        
        if (data.name === TabNames.PRODUCTS) 
        {
          // setSearchParams({q:'Class_Student'})
        }
        
    }
  }

  return (
    <>
      <Header activeItem={activeItem} handleItemClick={handleItemClick} />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/academics" element={<Product />} />
          <Route path="/mobile" element={<Mobile />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/demo" element={<Demo />} />
          <Route path="/schoollife" element={<SchoolLife />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/about-us" element={<About />} />
        </Routes>
      <Footer activeItem={activeItem} handleItemClick={handleItemClick}/>
      <ArrowUpwardOutlinedIcon className= {navBg ? ('Uparrow') : ('UparrowHiden')} onClick={scrollToTop}></ArrowUpwardOutlinedIcon>
    </>
  );
}