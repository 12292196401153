import React, {  } from 'react'
import './Mobile.css'
import { Header,  } from 'semantic-ui-react'
import { MobileTab } from './MobileTab'
import bgImage2 from "../../images/BG2.png";
import BApic1 from "../../images/beyondAcademics/BApic1.png";
import trophy from "../../images/beyondAcademics/Component 3.png";

export const Mobile = () => {
  return (
    <div className='mobile' style={{
      backgroundColor: 'rgba(70, 61, 163, 1)'
    }}>
     <div
        className='mobile__top'
        style={{ backgroundImage: `url(${bgImage2})`, backgroundSize: 'cover', background: 'cover' }}
      >
          <img src={BApic1} alt="" className="AcPic" />
         
        <Header as={'h1'} size='huge'>
      Beyond Academics
        </Header>
        
        <img src={trophy} alt="" className="icon2" />
        
      </div>
      <div className='mobile__bottom'>
        <MobileTab/>
      </div>
      
    </div>
  )
}