import React, {  } from 'react'
import './Product.css'
import { Header,  } from 'semantic-ui-react'
// import bgGround from '../../images/micro-controller-bg.png'
import { ProductsTab } from './ProductsTab'
import bgImage2 from "../../images/BG2.png";
import paperrocket from "../../images/Paper-Rocket.png";
import Apic1 from "../../images/Lab1.png";
import trophy from "../../images/trophy.png";

export const Product = () => {
  return (
    <div className='product' style={{
      backgroundColor: 'rgba(70, 61, 163, 1)'
    }}>
      <div
        className='product__top'
        style={{ backgroundImage: `url(${bgImage2})`, backgroundSize: 'cover', background: 'cover' }}
      >
          <img src={Apic1} alt="" className="AcPic" />
         
        <Header as={'h1'} size='huge'>
      Academics
        </Header>
        <img src={trophy} alt="" className="icon1" />
        <img src={paperrocket} alt="" className="icon2" />
      </div>
      
      <div className='product__bottom'>
        <ProductsTab/>
      </div>
      
    </div>
  )
}