
import { useEffect, useState } from 'react';


import './Product.css';
// import { useMediaQuery } from '@mui/material';

import AImage from "../../images/academics/a.svg";
import PP1 from "../../images/PP3.png";
import PP2 from "../../images/PP2.jpg";

import Plus from "../../images/academics/plus.svg";
import Minus from "../../images/academics/pink_cross.svg";
import Abacus from "../../images/academics/abacus.png";
import PinkA from "../../images/academics/pink_a.svg";
import Aletter from "../../images/academics/pink letter a.svg";
import Computer from "../../images/academics/computer screen.svg";



export const ProductsTab = () => {

  
 
  // let isMobileView = useMediaQuery("(max-width:900px)")




  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])



  const [active, setActive] = useState("pre_primary");
  return (
    // <Tab activeIndex={activeIndex}
    //   panes={panes} />

    <div className="main_section">
        <div className="tabs_wrapper">
          <div
            className={`tabs ${
              active === "pre_primary"
                ? "right"
                : active === "primary"
                ? "center"
                : "left"
            }`} 
          > 
            <span
              className={`${active === "pre_primary" && "active"}`}
              onClick={() => setActive("pre_primary")}
            >
              Pre Primary
            </span>
            <span
              className={`${active === "primary" && "active"}`}
              onClick={() => setActive("primary")}
            >
              Primary
            </span>
            <span
              className={`${active === "high_school" && "active"}`}
              onClick={() => setActive("high_school")}
            >
              High School
            </span>
          </div>
        </div>
        {active === "pre_primary" && (
          <div className="pre_primary_wrapper">
           
            <img src={PP1} alt="" className="a2_image" />
            <img src={AImage} alt="" className="a_image" />
            <div className="pre_primary_section_1">
              <div className="content">
                <h3>Pre Primary</h3>
                <p>
                  The pre-primary school curriculum is not just alphabets and
                  numbers but also focuses on fostering holistic development in
                  young learners. <br /> Language Development: <br /> •
                  Introduction to basic vocabulary and phonetics. <br /> •
                  Storytelling and nursery rhymes to enhance language skills.{" "}
                  <br /> Numeracy Skills:- <br /> • Introduction to numbers,
                  counting, and basic mathematical concepts.
                  <br /> • Hands-on activities to promote a practical
                  understanding of mathematical concepts. <br /> Social and
                  Emotional Development: <br /> • Building social skills through
                  group activities and play. <br />• Emotional expression and
                  understanding feelings. 
                </p>
              </div>
             
            </div>
            <div className="pre_primary_section_2">
              <div className="img">
                <img src={PP2} alt="" className="image2" />
                {/* <img src={PrePrimary2} alt="" className="pre_primary_2" /> */}
              </div>
              <div className="content2"> 
              <h3>Creative Arts:</h3>
                <p>
                  Arts and crafts to encourage creativity. Music and movement
                  activities.
                  <br /> Physical Development: <br /> • Outdoor Play: Encourage
                  activities like running, jumping, and climbing to develop
                  coordination.
                  <br /> Dancing and moment activities Ball games playing catch
                  or kicking a ball helps with hand-eye coordination. Fine
                  skills through drawing, writing, and other activities. <br />
                  Science and Nature Exploration: <br />• Basic introduction to
                  the natural world through simple experiments and observations.{" "}
                  <br />
                  Health and Personal Hygiene:
                  <br /> • Developing healthy habits and understanding personal
                  hygiene. <br />
                  Cognitive Development: <br /> • Cognitive games and puzzles to
                  stimulate thinking and problem-solving.
                  <br /> Environmental Studies: <br /> • Basic awareness of the
                  environment, seasons, and surroundings. <br /> Character
                  Education: <br /> • Values and character development through
                  stories and activities.
                </p>
              </div>
            </div>
          </div>
        )}
        {active === "primary" && (
          <div className="primary_wrapper">
            <div className="content">
              <h3>Primary</h3>
              <p>
                Core Academic Subjects: <br /> • TELUGU, HINDI, ENGLISH, MATHS,
                EVS Arts and crafts <br /> • Drawing, painting, and other art
                proiects.
              </p>
              <h4>Abacus</h4>
              <p>
                Introducing the abacus to primary school students can be a
                beneficial way toо  enhance their understanding of basic
                arithmetic and develop foundational math skills <br />
                • Interactive Demonstration: Begin with a hands-on demonstration
                of the abacus, <br /> • Mental Math Exercises: Gradually
                introduce mental math exercises where students visualize
                calculations on the abacus without physically moving beads.{" "}
                <br /> This helps develop mental math skills. <br />
                • Abacus aims to simplify and expedite mathematical calculations
                by providing alternative methods for arithmetic and algebraic
                operations. <br />• It improves SPEED & EFFICIENCY, VERSATILITY,
                MIND CALCULATIONS, <br />
                HAND WRITING DEVELOPMENT PROGRAM: <br />• Our hand writing
                expert teach Letter
              </p>
              <br />
              <p>
                Formation, Letter Connections, Spacing and Alignment. <br /> •
                Individualized Instruction, Regular Practice, Feedback and
                Correction <br /> • The goal of such a program is to facilitate
                the development of legible, fluent, and comfortable handwriting
                skills. <br />
                Technology Integration: <br /> • Basic computer skills <br /> •
                Introduction to educational software and digital literacy
              </p>
            </div>
            <img src={Plus} alt="svg" className="plus" />
            <img src={Minus} alt="svg" className="minus" />
            <img src={Abacus} alt="svg" className="abacus" />
            <img src={PinkA} alt="svg" className="pink_a" />
            <img src={Aletter} alt="svg" className="a_letter" />
            <img src={Computer} alt="svg" className="computer" />
          </div>
        )}
        {active === "high_school" && (
          <div className="pre_primary_wrapper">
            <div className="pre_primary_section_3">
            {/* <img src={PP1} alt="" className="a2_image" /> */}
              <div className="content">
                <h3>High School</h3>
                <p>
                  High school circulum is designed to prepare students for
                  further education, and  to equip them with a well-rounded set
                  of skills and knowledge for future endeavors. <br />
                  Arts and crafts <br />
                  • Drawing, painting, and other art projects. <br />
                  HAND WRITING DEVELOPMENT PROGRAM: <br />
                  LUCIDA: <br /> Lucida Handwriting is a cursive- style font
                  known for its clarity and readability that emulates the
                  appearance of handwritten <br />
                  • Our hand writing expert teach Letter Formation, Letter
                  Connections, Spacing and Alignment. <br />
                  Individualized Instruction, Regular Practice, Feedback and
                  Correction The goal of such a program is to facilitate the
                  development of Clarity, text. <br />
                  Readability, fluent, and comfortable handwriting skills.
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
  )
}