
import './GalleryModal.css'


import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';


import ImageGallery from "react-image-gallery";


export const GalleryModal = ( props :{
  open: any,
  onClose: any,
  items: any
}
  
  ) => {
  return (
    <>
      
      <Modal
        open={props.open}
        onClose={props.onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        
      > 
      
        <Box className='box'
        // sx={style}
        >
          <div 
          style={{
            display:"flex",
            paddingLeft:'20px',
            alignSelf:'center',
            justifyContent:'flex-end',
            alignContent:'flex-end',
            alignItems:'flex-end'}}
          > 
          <Button onClick={props.onClose} 
          style={{
            backgroundColor:'#4D44B5',
          color:'#ffffff',
          height:'50px',
          fontSize:'20px',
          padding:'20px',
          
          borderRadius:'10px'}} >Close</Button>
          </div>
       
        <div className='box_div'>
        <ImageGallery 
    
    useBrowserFullscreen={true} 
    showPlayButton={false} 
   
    showFullscreenButton={false}
    thumbnailPosition="bottom"
    items={props.items}
    
    />;
        </div>
        
          
        </Box>
      </Modal>
    
    </>
  );
}