import React, { } from 'react'
import './Mobile.css'




// import { useMediaQuery } from '@mui/material';

import Meditation from '../../images/Today2.jpg'
import PE from '../../images/PE.jpg'
import Cultutal from '../../images/CA.jpg'
import Arts from '../../images/beyondAcademics/arts.svg'
import pinWheel from '../../images/beyondAcademics/pinWheel.json'
import Lottie from "lottie-react";

export const MobileTab = () => {

 

  
  
  
  




  return (
    
    <div className="main_section1">
        <div className="sub_section">
          <div className="content">
            <h3>Meditation</h3>
            <p>
               The sports curriculum at SGHS is planned and designed for
              students to develop and enhance skills such as strength, speed,
              endurance, agility, flexibility, control, balance, as well as
              team-work, leadership, confidence and self-discipline.
            </p>
          </div>
          <img  className="medi" src={Meditation} alt="" />
        </div>

        <div className="arts_section">
        {/* <img className="img1" src={Artsp} alt="" /> */}
          <img src={Arts} alt="" />
          <div className="content">
            <Lottie
              animationData={pinWheel}
              loop={true}
              autoplay={true}
              className="lottie"
            />
                        <h3>Arts & Crafts</h3>
            <p>
              SCHS culture has always valued participation and teamwork and lays
              a strong emphasis on co-curricular activities. The school was
              founded on the principle of imparting holistic education with an
              equal emphasis on arts, sports and academics. The Physical
              Education Curriculum at school provides the right balance of
              activities to develop children’s gross and fine motor skills.
            </p>
          </div>
        </div>
        <div className="sub_section">
          <div className="content">
            <h3>Physical Education</h3>
            <p>
               The sports curriculum at SGHS is planned and designed for
              students to develop and enhance skills such as strength, speed,
              endurance, agility, flexibility, control, balance, as well as
              team-work, leadership, confidence and self-discipline.
            </p>
          </div>
          <img src={PE} alt="" />
        </div>
        <div className="sub_section  cultural_Section">
          <img src={Cultutal} alt="" />
          <div className="content">
            <h3>Cultural Activities</h3>
            <p>
               The sports curriculum at SGHS is planned and designed for
              students to develop and enhance skills such as strength, speed,
              endurance, agility, flexibility, control, balance, as well as
              team-work, leadership, confidence and self-discipline.
            </p>
          </div>
        </div>
      </div>
  )
}