import React, { useState } from 'react';
import { Button,  } from 'semantic-ui-react';
import bgImage2 from "../../images/BG2.png";
import Eop2 from '../../images/rainbow1.png';
import logo2 from '../../images/RT1.jpeg';
import bgImage1 from "../../images/heroBG.png";

import logo from '../../images/GD1.jpg';
import './Home.css';
import Slide1 from '../../images/HeroSlide1.png';
import Slide2 from '../../images/Hero1.png';
import Slide3 from '../../images/Hero2.png';
import Bro from '../../images/Brochar2.jpeg';
import Knowmore from '../../images/KnowmoreB.png';

import NoticeBoardIcon from '../../images/icons/notice_board_icon.svg';
import NoteIcon from '../../images/icons/note_icon.svg';

import { useNavigate } from 'react-router-dom';
import { Swiper, SwiperSlide } from "swiper/react";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper/modules";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { GalleryModal } from '../GalleryModal/GalleryModal';
import GD1 from "../../images/GD1.jpg"
import GD2 from "../../images/GD2.jpeg"
import GD3 from "../../images/GD3.jpeg"
import GD4 from "../../images/GD4.jpeg"
import GD5 from "../../images/GD5.jpeg"
import RT1 from "../../images/RT1.jpeg"
import RT2 from "../../images/RT2.jpeg"
import RT3 from "../../images/RT3.jpeg"
import RT4 from "../../images/RT4.jpeg"
import Modal from '@mui/material/Modal';


const noticeBoardContent = [
  {
    title: "Parent Teacher Meeting",
    content: " 29th September 2024",
  },
  {
    title: "Dussehra Holidays",
    content: " 2nd October - 14th October 2024",
  },
  {
    title: "Summative Assessment - I Exams",
    content: "  21st October - 30th October2024",
  },
];


const Fullscreen = (item: any) => {
  return(<div style={{height:'70vh',alignContent:'center',width:'100%',}}>
   <img
              // alt="sample video cover"
              className="image-gallery-image"
              // style={{height:'40%',backgroundColor:'red'}}
              src={item.original}
            />
  </div>)
}
const images1 = [
  {
    original: GD1,
    thumbnail: GD1,
    renderItem: Fullscreen
    
  },
  {
    original: GD2,
    thumbnail: GD2,
    renderItem: Fullscreen
  },
  {
    original: GD3,
    thumbnail:  GD3,
    renderItem: Fullscreen
  },
  {
    original: GD4,
    thumbnail:  GD4,
    renderItem: Fullscreen
  },
  {
    original: GD5,
    thumbnail:  GD5,
    renderItem: Fullscreen
  },
];

const images = [
  {
    original: RT1,
    thumbnail: RT1,
    // sizes:30
    renderItem: Fullscreen
    
  },
  {
    original: RT2,
    thumbnail: RT2,
    renderItem: Fullscreen
  },
  {
    original: RT3,
    thumbnail:  RT3,
    renderItem: Fullscreen
  },
  {
    original: RT4,
    thumbnail:  RT4,
    renderItem: Fullscreen
  },
];



export const MottoSection = () => {


  const [openB, setOpenB] = React.useState(true);
  // const navigate = useNavigate();

  const handleOpen = () => setOpenB(true);
  const handleClose = () => setOpenB(false);
  const scrollToTop = () => {
    window.scrollTo(0, 0);
}


const navigate = useNavigate();


  const params = {
    autoplay: {
      delay: 2000,
      disableOnInteraction: false,
    },
  };

  return (
    <section className='hero' style={{ 
      backgroundImage: `url(${bgImage1})`,
       backgroundSize: 'cover', background: 'cover' }}>
      <div className='hero__flex' >
      <div className="hero_slider_wrapper">

      <Modal
        open={openB}
        onClose={handleClose}
        >

<div className='modal'
        // sx={style}
        >
          <div 
          style={{
            display:"flex",
            paddingLeft:'20px',
            alignSelf:'center',
            // backgroundColor:'red',
            justifyContent:'flex-end',
            alignContent:'flex-end',
            alignItems:'flex-end'}}
          > 
          <Button onClick={handleClose} 
          style={{
            backgroundColor:'#4D44B5',
          color:'#ffffff',
          height:'50px',
          fontSize:'20px',
          padding:'20px',     
          borderRadius:'10px',
          // display: 'flex',
          paddingBottom: '40px',
          alignSelf:'center',
          justifyContent:'center',
            alignContent:'center',
            alignItems:'center'
          }} >Close</Button>
          </div>
       
        <div className='modal_div'>
        <img  className="imgB" src={Bro} alt="" />
        </div>
        
          
        </div>
        </Modal>
        <Swiper
        className='swiper'
          modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
          spaceBetween={0}
          slidesPerView={1}
          navigation
          loop={true}
          // direction="vertical"
          pagination={{ clickable: true }}
          speed={2000}
          {...params}
          autoplay={{
            reverseDirection:true
          }}
        >
          <SwiperSlide className="slide1" onClick={() => {
              navigate("/about-us")
              scrollToTop()
            }}>
            <img  className="img"src={Slide1} alt="" />
          </SwiperSlide>
          <SwiperSlide className="slide2" onClick={handleOpen}>
            <img  className="img" src={Slide3} alt="" />
          </SwiperSlide>
          <SwiperSlide className="slide2"  >
            <img  className="img" src={Slide2} alt="" />
          </SwiperSlide>
          
        </Swiper>
      </div>
        {/* <div className='hero__right' style={{ backgroundImage: `url(${bgGround})` }}>
        </div> */}
      </div>
    </section>
  )
}

export const DescriptionSection = () => {


  const scrollToTop = () => {
    window.scrollTo(0, 0);
}

  const navigate = useNavigate();
  return (
    <section className="second-section" style={{ 
      backgroundImage: `url(${bgImage2})`,
       backgroundSize: 'cover', background: 'cover' }}>
       
      
     
      <div className="second-section__right2">

        <div className="content">
        <p  className='header'>Welcome To</p>
        <h1 className='title'> SAI GRAMMAR HIGH <br /> SCHOOL</h1>

          <p className='para'>
          Established in 2002 , SAI GRAMMAR HIGH SCHOOL was founded A School with ordinary fees providing corporate 
          level Facilities & Education for children to develop their skills in arts, sports, and technology. 
          Over the last 22 years, SAI GRAMMAR HIGH SCHOOL has grown to become a consistently 
          No.1 school by giving personal attention to each and every student by nurturing places 
          where everyone - teachers, students, management and also the parents feel equally 
          responsible to grow together as a team and attain greater height.
          <br></br>
          
          </p>
          <Button className='buttonimg'  
           onClick={() => {
              navigate("/about-us")
              scrollToTop()
            }}
            >
          <img  className="img" src={Knowmore} alt='Qshikshak'   />
          </Button>
        </div>
        <div className='des_image'>
        <img src={Eop2} alt='Qshikshak' className='icon'  />
        </div>
        
      </div>
    
    </section>
  );
}

export const DescriptionSection2 = () => {

  const [noticeBoard, setNoticeBoard] = useState(noticeBoardContent);
  return (
    <section className="second-section2" style={{backgroundColor:'rgba(221, 221, 221, 1)', backgroundSize: 'cover', background: 'cover' }}>
       <img src={NoticeBoardIcon} alt="" className="notice_board_icon" />
        <img src={NoteIcon} alt="" className="note_icon" />
        <div className="notice_board">
          <ul>
            {noticeBoard.map((item, index) => {
              return (
                <li key={index}>
                  <h4>{item.title}</h4>
                  <p>{item.content}</p>
                </li>
              );
            })}
          </ul>
        </div>
      


    </section>
  );
}

export const FeaturesSection = () => {


  const scrollToTop = () => {
    window.scrollTo(0, 0);
}

  const navigate = useNavigate();

  const [open1, setOpen1] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const handlesOpen1 = () => setOpen1(true);
  const handlesOpen2 = () => setOpen2(true);
  const handlesClose1 = () => setOpen1(false);
  const handlesClose2 = () => setOpen2(false);
  return (
    <section className="feature" style={{ backgroundImage: `url(${bgImage2})`, backgroundSize: 'cover', background: 'cover', }}>
      <h1 className="feature__heading">Life at SGHS</h1>
      <div className='feature__content'>
        <div className="feature__flex">
          <Button className="feature__item"
             onClick={handlesOpen1}
            >  
               <img
                src={logo}
                alt="Class"
                className="feature__item-image"
              />
            <div className="feature__item-heading">
              <h3><strong className="bold-text-2">GreenDay Celebrtions<br></br></strong></h3>
              
            </div>
          
          </Button>
          <GalleryModal
          open={open1}
          onClose={handlesClose1}
           items={images1} 
          />
          <Button className="feature__item"
            onClick={handlesOpen2}
            >
              <img
                src={logo2}
                alt="Under Voltage
                Protection
                "
                className="feature__item-image"
              />
            <div className="feature__item-heading">
              
              <h3><strong className="bold-text-2">Ramoji Filmcity Trip<br></br></strong></h3>
            
            </div>
           
            
          </Button>
          <GalleryModal
          open={open2}
          onClose={handlesClose2}
           items={images} 
          />
        </div>
        <Button className='buttonimg' 
          onClick={() => {
              navigate("/gallery")
              scrollToTop();
            }}
        >
          <img  className="img" src={Knowmore} alt='Qshikshak'   />
          </Button>
      </div>
    </section>
  );
}

export const Home = () => {
  return (
    <>
      <MottoSection />
      <DescriptionSection />
      <DescriptionSection2 />
      <FeaturesSection />
    </>
  )
}
