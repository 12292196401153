import React, { useEffect, useState } from 'react'
import { Menu, MenuItemProps, Button } from 'semantic-ui-react'
import './Header.css'
import logo from '../../images/LogoMain.png'
import { Link } from 'react-router-dom'
import { Icon } from 'semantic-ui-react'
import callIcon from '../../images/icons/phone_nav.svg';
import { useNavigate } from 'react-router-dom';

export enum TabNames {
    HOME = 'Home',
    PRODUCTS = "Academics",
    MOBILE = "Beyond Academics",
    CONTACT = "Contact",
    DEMO = "Admissions",
    SCHOOLLIFE = "School Life",
    GALLERY = "Gallery",
    ABOUT_US = "About Us",
}

export const Header = (props: {
    activeItem: TabNames,
    handleItemClick: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, data: MenuItemProps) => void
}) => {
    const [open, setOpen] = useState<boolean>(false);

    const [navBg, setNavBg] = useState(false);

    const navigate = useNavigate();
    const changeNavBg = () => {
        window.scrollY >= 150 ? setNavBg(true) : setNavBg(false);
    }

    useEffect(() => {
        window.addEventListener('scroll', changeNavBg);
        return () => {
            window.removeEventListener('scroll', changeNavBg);
        }
    }, [])

    const scrollToTop = () => {
        window.scrollTo(0, 0);
    }

    return (
        <div className={!navBg  ? ('top-bar'): ('top-bar_active' )}>
            <a className='logo-link' href='/'>
                <img src={logo} alt='SGHS' className={open ? 'logo-hide' : 'logo'} />
             
            </a>
            <div className={'mobile-bar'} onClick={() => {
                setOpen(!open)
            }}>
                <Icon name='bars' sx={{ color: "var(--line)" }} size='big' />
            </div>
                <div  className={open ? 'rowblock2' : 'rowblock2hide' }>
                <div className='block2'>
                <img src={callIcon} alt='SGHS' height={20} style={{paddingRight: -10}}/>
                        <div className='text' >
                            
                            9000284777
                        </div>
                        <div className='hero_button'>
                            <Button primary onClick={() => {
              navigate("/demo")
              scrollToTop();
              setOpen(false)
            }}><div 
            // href='/demo'
             className='button'style={{ color: '#4F1C67',  }}>Admissions Open</div></Button>
                        </div>
                    </div>
               
            <Menu  className={'header-layout' } 
            stackable
            >
                <Menu.Menu
                    position='right'
                    className={ open ?'nav-bar-open' : 'nav-bar-hide'}
                >
                    <Menu.Item
                        name={TabNames.HOME}
                        active={props.activeItem === TabNames.HOME}
                        onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, data: MenuItemProps) => {
                            props.handleItemClick(event, data);
                            setOpen(false);
                            // scrollToTop();
                            scrollToTop();
                        }}
                        as={Link}
                        to='/'
                    // className='tab-style'
                    // color=''
                    />
                    <Menu.Item
                        name={TabNames.PRODUCTS}
                        active={props.activeItem === TabNames.PRODUCTS}
                        onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, data: MenuItemProps) => {
                            props.handleItemClick(event, data);
                            setOpen(false);
                            scrollToTop();
                        }}
                        as={Link}
                        to='/academics'
                    // color='red'
                    />
                    <Menu.Item
                        name={TabNames.MOBILE}
                        active={props.activeItem === TabNames.MOBILE}
                        onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, data: MenuItemProps) => {
                            props.handleItemClick(event, data);
                            setOpen(false);
                            scrollToTop();
                        }}
                        as={Link}
                        to='/mobile'
                    />
                   
                     <Menu.Item
                        name={TabNames.SCHOOLLIFE}
                        active={props.activeItem === TabNames.SCHOOLLIFE}
                        onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, data: MenuItemProps) => {
                            props.handleItemClick(event, data);
                            setOpen(false);
                            scrollToTop();
                        }}
                        as={Link}
                        to='/schoollife'
                    />
                     <Menu.Item
                        name={TabNames.GALLERY}
                        active={props.activeItem === TabNames.GALLERY}
                        onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, data: MenuItemProps) => {
                            props.handleItemClick(event, data);
                            setOpen(false);
                            scrollToTop();
                        }}
                        as={Link}
                        to='/gallery'
                    />
                    <Menu.Item
                        name={TabNames.ABOUT_US}
                        active={props.activeItem === TabNames.ABOUT_US}
                        onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, data: MenuItemProps) => {
                            props.handleItemClick(event, data);
                            setOpen(false);
                            scrollToTop();
                        }}
                        as={Link}
                        to='/about-us'
                    />
                     <Menu.Item
                        name={TabNames.CONTACT}
                        active={props.activeItem === TabNames.CONTACT}
                        onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, data: MenuItemProps) => {
                            props.handleItemClick(event, data);
                            setOpen(false);
                            scrollToTop();
                        }}
                        as={Link}
                        to='/contact'
                    />
                 

                </Menu.Menu>
            </Menu>
            </div>

        </div>
    )
}