import React, {  } from 'react'
import './Gallery.css'

import Button from '@mui/material/Button';


import { GalleryModal } from '../GalleryModal/GalleryModal';

import GD1 from "../../images/GD1.jpg"
import GD2 from "../../images/GD2.jpeg"
import GD3 from "../../images/GD3.jpeg"
import GD4 from "../../images/GD4.jpeg"
import GD5 from "../../images/GD5.jpeg"
import RT1 from "../../images/RT1.jpeg"
import RT2 from "../../images/RT2.jpeg"
import RT3 from "../../images/RT3.jpeg"
import RT4 from "../../images/RT4.jpeg"
import NM1 from "../../images/NM1.jpeg"
import NM2 from "../../images/NM2.jpeg"
import NM3 from "../../images/NM3.jpeg"
import NM4 from "../../images/NM4.jpeg"
import NM5 from "../../images/NM5.jpeg"
import Club from "../../images/Club.jpg"
import HK from "../../images/HK.jpg"
import Event1 from "../../images/event1.jpg"
import Event2 from "../../images/KA2.jpg"
import Event3 from "../../images/Today1.jpg"
import YD1 from "../../images/YD1.jpg"
import YD2 from "../../images/YD2.jpg"
import YD3 from "../../images/YD3.jpg"
import YD4 from "../../images/YD4.jpg"
import YeD1 from "../../images/YeD1.jpg"
import YeD2 from "../../images/YeD2.jpg"
import YeD3 from "../../images/YeD3.jpg"
import KrA1 from "../../images/KrA1.jpg"
import KrA2 from "../../images/KrA2.jpg"
import KrA3 from "../../images/KrA3.jpg"
import DD1 from "../../images/DD1.jpg"
import DD2 from "../../images/DD2.jpg"
import DD3 from "../../images/DD3.png"
import PR1 from "../../images/PR1.jpg"
import PR2 from "../../images/PR2.jpg"
import PR3 from "../../images/PR3.jpg"
import BA01 from "../../images/BA01.jpg"
import BA02 from "../../images/BA02.jpg"
import BA03 from "../../images/BA03.jpg"
// import { Fullscreen } from '@mui/icons-material';




const Fullscreen = (item: any) => {
  return(<div style={{height:'70vh',
  display:'flex',
  alignContent:'center',
  width:'100%',
   justifyContent:'center',
   alignItems:'center',
   justifyItems:'center',
  //  backgroundColor:'red',
   
   }}>
   <img
              // alt="sample video cover"
              className="image-gallery-image"
              // style={{height:'40%',backgroundColor:'red'}}
              src={item.original}
            />
  </div>)
}

const images2 = [
  {
    original: NM1,
    thumbnail: NM1,
    renderItem: Fullscreen
    
  },
  {
    original: NM2,
    thumbnail: NM2,
    renderItem: Fullscreen
  },
  {
    original: NM3,
    thumbnail:  NM3,
    renderItem: Fullscreen
  },
  {
    original: NM4,
    thumbnail:  NM4,
    renderItem: Fullscreen
  },
  {
    original: NM5,
    thumbnail:  NM5,
    renderItem: Fullscreen
  },
];

const images001 = [
  {
    original: YD1,
    thumbnail: YD1,
    // sizes:30
    renderItem: Fullscreen
    
  },
  {
    original: YD2,
    thumbnail: YD2,
    renderItem: Fullscreen
  },
  {
    original: YD3,
    thumbnail:  YD3,
    renderItem: Fullscreen
  },
  {
    original: YD4,
    thumbnail:  YD4,
    renderItem: Fullscreen
  },
  
];
const images002 = [
  {
    original: YeD1,
    thumbnail: YeD1,
    // sizes:30
    renderItem: Fullscreen
    
  },
  {
    original: YeD2,
    thumbnail: YeD2,
    renderItem: Fullscreen
  },
  {
    original: YeD3,
    thumbnail:  YeD3,
    renderItem: Fullscreen
  },
];
const images003 = [
  {
    original: KrA1,
    thumbnail: KrA1,
    // sizes:30
    renderItem: Fullscreen
    
  },
  {
    original: KrA2,
    thumbnail: KrA2,
    renderItem: Fullscreen
  },
  {
    original: KrA3,
    thumbnail:  KrA3,
    renderItem: Fullscreen
  },
];
const images004 = [
  {
    original: PR1,
    thumbnail: PR1,
    // sizes:30
    renderItem: Fullscreen
    
  },
  {
    original: PR2,
    thumbnail: PR2,
    renderItem: Fullscreen
  },
  {
    original: PR3,
    thumbnail:  PR3,
    renderItem: Fullscreen
  },
];
const images005 = [
  {
    original: DD1,
    thumbnail: DD1,
    // sizes:30
    renderItem: Fullscreen
    
  },
  {
    original: DD2,
    thumbnail: DD2,
    renderItem: Fullscreen
  },
  {
    original: DD3,
    thumbnail:  DD3,
    renderItem: Fullscreen
  },
];
const images006 = [
  {
    original: BA01,
    thumbnail: BA01,
    // sizes:30
    renderItem: Fullscreen
    
  },
  {
    original: BA02,
    thumbnail: BA02,
    renderItem: Fullscreen
  },
  {
    original: BA03,
    thumbnail:  BA03,
    renderItem: Fullscreen
  },
];

const images1 = [
  {
    original: GD1,
    thumbnail: GD1,
    // sizes:30
    renderItem: Fullscreen
    
  },
  {
    original: GD2,
    thumbnail: GD2,
    renderItem: Fullscreen
  },
  {
    original: GD3,
    thumbnail:  GD3,
    renderItem: Fullscreen
  },
  {
    original: GD4,
    thumbnail:  GD4,
    renderItem: Fullscreen
  },
  {
    original: GD5,
    thumbnail:  GD5,
    renderItem: Fullscreen
  },
];
const images = [
  {
    original: RT1,
    thumbnail: RT1,
    // sizes:30
    renderItem: Fullscreen
    
  },
  {
    original: RT2,
    thumbnail: RT2,
    renderItem: Fullscreen
  },
  {
    original: RT3,
    thumbnail:  RT3,
    renderItem: Fullscreen
  },
  {
    original: RT4,
    thumbnail:  RT4,
    renderItem: Fullscreen
  },
];
const imagesE = [
  {
    original: Event1,
    thumbnail: Event1,
    // sizes:30
    renderItem: Fullscreen
    
  },
  {
    original: Event2,
    thumbnail: Event2,
    renderItem: Fullscreen
  },
  {
    original: Event3,
    thumbnail:  Event3,
    renderItem: Fullscreen
  },
  {
    original: RT4,
    thumbnail:  RT4,
    renderItem: Fullscreen
  },
];
const imagesC = [
  {
    original: Club,
    thumbnail: Club,
    // sizes:30
    renderItem: Fullscreen
    
  },
 
];
const imagesH = [
  {
    original: HK,
    thumbnail: HK,
    // sizes:30
    renderItem: Fullscreen
    
  },
 
];

export const GalleryTab = () => {


  // let isMobileView = useMediaQuery("(max-width:900px)")
  // let [searchParams, setSearchParams] = useSearchParams();
 

  // const scrollToTop = () => {    
  //   if (isMobileView) {
  //     window.scrollTo(0,500);
  //   } else {
  //     window.scrollTo(0,300);
  //   }
  //  }

  
  
  const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [open3, setOpen3] = React.useState(false);
  const [open4, setOpen4] = React.useState(false);
  const [open5, setOpen5] = React.useState(false);
  const [open6, setOpen6] = React.useState(false);
  const [open7, setOpen7] = React.useState(false);
  const [open8, setOpen8] = React.useState(false);
  const [open9, setOpen9] = React.useState(false);
  const [open10, setOpen10] = React.useState(false);
  const [open11, setOpen11] = React.useState(false);





  const handleOpen = () => setOpen(true);
  const handleOpen1 = () => setOpen1(true);
  const handleOpen2 = () => setOpen2(true);
  const handleOpen3 = () => setOpen3(true);
  const handleOpen4 = () => setOpen4(true);
  const handleOpen5 = () => setOpen5(true);
  const handleOpen6 = () => setOpen6(true);
  const handleOpen7 = () => setOpen7(true);
  const handleOpen8 = () => setOpen8(true);
  const handleOpen9 = () => setOpen9(true);
  const handleOpen10 = () => setOpen10(true);
  const handleOpen11 = () => setOpen11(true);






  const handleClose = () => setOpen(false);
  const handleClose1 = () => setOpen1(false);
  const handleClose2 = () => setOpen2(false);
  const handleClose3 = () => setOpen3(false);
  const handleClose4 = () => setOpen4(false);
  const handleClose5 = () => setOpen5(false);
  const handleClose6 = () => setOpen6(false);
  const handleClose7 = () => setOpen7(false);
  const handleClose8 = () => setOpen8(false);
  const handleClose9 = () => setOpen9(false);
  const handleClose10 = () => setOpen10(false);
  const handleClose11 = () => setOpen11(false);
  // const ImageGallery = ImageGallery;


  return (
    // <Tab activeIndex={activeIndex}
    //   panes={panes} />
    <div className="main_section3">
      <h3>Gallery Search</h3>
      <h3>Year 2024-25</h3>
      <div className="sub_section">
        
          <div className="content">
        <Button className="imgdiv"  onClick={handleOpen6}>  <img  className="img"src={YD1} alt="" />
           </Button> <p>Yoga Day</p>
           <GalleryModal
          open={open6}
          onClose={handleClose6}
           items={images001} 
          />
          </div>
          <div className="content">
        <Button className="imgdiv"  onClick={handleOpen7}>  <img className="img" src={YeD1} alt="" />
           </Button> <p>Yellow Day</p>
           <GalleryModal
          open={open7}
          onClose={handleClose7}
           items={images002} 
          />
          </div>
          
        
          <div className="content">
        <Button className="imgdiv"  onClick={handleOpen8}>  <img className="img" src={KrA1} alt="" />
           </Button> <p>Krishnashtami</p>
           <GalleryModal
          open={open8}
          onClose={handleClose8}
           items={images003} 
           
          />
          </div>
         
                  </div>
                  <div className="sub_section">
        
        <div className="content">
        <Button className="imgdiv"  onClick={handleOpen11}>  <img  className="img"src={BA01} alt="" />
         </Button> <p>Bathukamma Clebration</p>
         <GalleryModal
        open={open11}
        onClose={handleClose11}
         items={images006} 
        />
        </div>
        <div className="content">
      <Button className="imgdiv"  onClick={handleOpen9}>  <img  className="img"src={DD1} alt="" />
         </Button> <p>Doctor Day</p>
         <GalleryModal
        open={open9}
        onClose={handleClose9}
         items={images004} 
        />
        </div>
        <div className="content">
      <Button className="imgdiv"  onClick={handleOpen10}>  <img className="img" src={PR1} alt="" />
         </Button> <p>Play Room</p>
         <GalleryModal
        open={open10}
        onClose={handleClose10}
         items={images005} 
        />
        </div>
        
      
        
       
                </div>
      <h3>Year 2023</h3>
        <div className="sub_section">
        
          <div className="content">
        <Button className="imgdiv"  onClick={handleOpen1}>  <img  className="img"src={GD1} alt="" />
           </Button> <p>GreenDay Celebrtions</p>
           <GalleryModal
          open={open1}
          onClose={handleClose1}
           items={images1} 
          />
          </div>
          <div className="content">
        <Button className="imgdiv"  onClick={handleOpen2}>  <img className="img" src={RT1} alt="" />
           </Button> <p>Ramoji Filmcity Trip</p>
           <GalleryModal
          open={open2}
          onClose={handleClose2}
           items={images} 
          />
          </div>
          
        
          <div className="content">
        <Button className="imgdiv"  onClick={handleOpen3}>  <img className="img" src={NM1} alt="" />
           </Button> <p>National Maths Day</p>
           <GalleryModal
          open={open3}
          onClose={handleClose3}
           items={images2} 
           
          />
          </div>
         
                  </div>
                  <div className="sub_section">
        
        <div className="content">
      <Button className="imgdiv"  onClick={handleOpen5}>  <img  className="img"src={Event1} alt="" />
         </Button> <p>Events</p>
         <GalleryModal
        open={open5}
        onClose={handleClose5}
         items={imagesE} 
        />
        </div>
        <div className="content">
      <Button className="imgdiv"  onClick={handleOpen}>  <img className="img" src={HK} alt="" />
         </Button> <p>Hongkong Trip</p>
         <GalleryModal
        open={open}
        onClose={handleClose}
         items={imagesH} 
        />
        </div>
        
      
        <div className="content">
      <Button className="imgdiv"  onClick={handleOpen4}>  <img className="img" src={Club} alt="" />
         </Button> <p>Child Safety Club</p>
         <GalleryModal
        open={open4}
        onClose={handleClose4}
         items={imagesC} 
         
        />
        </div>
                </div>
      </div>
  )
}



