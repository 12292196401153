import React, { useEffect,  } from 'react'
import './SchoolLife.css'


export const SchoolLifeTab = () => {


  


  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


  return (
    
    <div className="main_section2">
        <div className="sub_section">
          <div className="content">
            <h3>Smart classrooms</h3>
            <p>
              Our classrooms are large, spacious, and well-lit. These
              state-of-the-art, technology-enabled classrooms with ergonomic
              furniture offer plenty of natural ventilation. Interactive Boards
              have made it easier to utilize online resources, display 3D
              models, organize virtual field trips to different parts of the
              world, and create a rich learning experience for students.
            </p>
          </div>
          {/* <img src={Laboratory2} alt="" /> */}
        </div>
        <div className="sub_section cultural_Section">
          {/* <img src={Laboratory} alt="" /> */}

          <div className="content">
            <h3>Science Laboratories</h3>
            <p>
              Science laboratory: is a dedicated space equipped with tools,
              equipment, and resources for conducting practical experiments and
              hands-on activities with safety gear, related to
              various scientific disciplines.
            </p>
          </div>
        </div>
        <div className="sub_section">
          <div className="content">
            <h3>Biology Laboratories</h3>
            <p>
              Biology laboratory: students are entitled with A well equipped
              biology lab with Biological specimens, microbiology station,
              model's and charts, top class safety gear, dissection kits, lab
              manual Technology
            </p>
          </div>
          {/* <img src={ClassRoom} alt="" /> */}
        </div>
        <div className="sub_section arts_section">
          {/* <img src={ClassRoom} alt="" /> */}
          <div className="content">
            <h3>Libraries</h3>
            <p>
              Each Section has a library that is a storehouse of knowledge for
              different age groups. To encourage reading and to make the
              experience even more delightful, we provide our younger students
              with reading corners. The library has been given a modern touch
              with Bean Bags, Sofas, and a small amphitheater to make reading
              more fun for the students.
            </p>
          </div>
        </div>
        <div className="sub_section  vedic_Section ">
          <div className="content">
            <h3>VEDIC MATHEMATICS</h3>
            <p>
              Vedic Mathematics is a system of mathematical techniques that
              originated in ancient India. Vedic Mathematics aims to simplify
              and expedite mathematical calculations by providing alternative
              methods for arithmetic and algebraic operations. • It improves
              SPEED &EFFICIENCY, VERSATILITY, MIND CALCULATIONS.
            </p>
          </div>
          {/* <img src={Laboratory} alt="" /> */}
        </div>
      </div>
  )
}