import React from "react";
import {  Header } from "semantic-ui-react";

import "./About.css";
import bgImage2 from "../../images/BG2.png";
import Eop2 from '../../images/rainbow1.png';

export const About = () => {
  return (
    <>
      <div className="about"  style={{ backgroundImage: `url(${bgImage2})`, backgroundSize: 'cover', background: 'cover' }}>
        <div className="about-title2">
          <h1>About SGHS</h1>
        </div>
      <div className="about-content">
      <div className="about-item1">
          
          <div className="text-section">
            <Header as={"h2"} size="large">
            Sai Grammar high School
            </Header>
            <p>
            Established in 2002 , SAI GRAMMAR HIGH SCHOOL was founded A School with ordinary 
            fees providing corporate level Facilities & Education for children to develop 
            their skills in arts, sports, and technology. Over the last 22 years, 
            SAI GRAMMAR HIGH SCHOOL has grown to become a consistently No.1 school 
            by giving personal attention to each and every student by nurturing places 
            where everyone - teachers, students, management and also the parents feel 
            equally responsible to grow together as a team and attain greater height.
              <br></br>
              SAI GRAMMAR HIGH SCHOOL offers Play-way method for Preprimary classes. 
          CBSE curriculum for the classes Nursery to V and SSC for VI TO X. 
          Following Modern and latest teaching techniques, discipline through example 
          and encouragement, excellent facilities for co-curricular and sporting activities, 
          make the School an ideal platform for the physical, mental, emotional, 
          and spiritual growth and also Celebrating Various festivals in order to 
          create awareness on Real Indian Culture.
              <br></br>
              
            </p>
          </div>
          <div className="lottie-sectionImg1">
          <img src={Eop2} alt='Qshikshak' className='icon1'  />
          </div>
        </div>
       
      
        <div className="about-item1">
          <div className="why-section">
            <Header as={"h2"} size="large">
              Why SGHS
            </Header>
            <ul>
              <li>Own Multi storied buildings with spacious, well ventilated Class rooms are arranged with 
                C.C. CAMERAS and good infrastructure 100% Results in SSC Every Year.</li>
              <li>A School with ordinary fees providing corporate level Facilities & Education</li>
              <li>
              Teaching in play way method for Pre Primary students
              </li>
              <li>
              Well Equipped Computer & Science Labs
              </li>
              <li>
              Spoken English, Abacus & Vedic Math Classes Personal Attention given to every student
Encouragement in Extra & Co-Curricular activities Like Sports, Games, Dance etc.
              </li>
              <li>
              Run by qualified, experienced and dedicated team of academicians
Well Spacious Play Ground
              </li>
            </ul>
          </div>
        </div>
       
      </div>
    </div>
    </>
  );
}