import React, { } from 'react'
import { Menu, MenuItemProps } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { TabNames } from '../Header/Header'
import img from '../../images/LogoMain.png'
import img2 from '../../images/footer_bg.png';
import pecock from '../../images/pecock.png';
import './Footer.css'

import callIcon from '../../images/icons/phone.svg';
import clockIcon from '../../images/icons/clock.svg';
import facbookIcon from '../../images/icons/facebook.svg';
import locationIcon from '../../images/icons/location.svg';
import mailIcon from '../../images/icons/mail.svg';
import phoneIcon from '../../images/icons/instagram.svg';
import whatsappIcon from '../../images/icons/whatsapp.svg';
import arrow from '../../images/icons/arrow.svg';

export const Footer = (props: {
    activeItem: TabNames,
    handleItemClick: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, data: MenuItemProps) => void
}) => {

    const scrollToTop = () => {
        window.scrollTo(0, 0);
    }

    return (
        <footer className='footer' style={{ backgroundImage: `url(${img2})`, backgroundSize: 'cover', background: 'cover' }}>
            <img className="peacock" src={pecock} alt='SGHS' />
            <div className='footer__item1'>
                <a href='/'>  <img src={img} alt='SGHS' height={50} /></a>
                <div  >( Recognised by Govt of T.S. )</div>

            </div>
            <div className='footer__top'>
                {/* <img src={img} alt='optimump' height={50} /> */}
                <div className='footer__item'>


                    {/* <div className='footer-menu-title'><h4>Reach us</h4></div> */}
                    <div className='footer-flex'>
                        <div className='footer-menu-item2'>
                            <img src={callIcon} alt='SGHS' height={50} style={{ paddingRight: 10 }} />
                            <div >
                                <div style={{ fontSize: '18px', color: '#A1238E', paddingBottom: '10px', paddingTop: '10px' }} >Call</div>
                                <div  >+919000284777</div>
                                <div  >+919000278777</div>
                            </div>
                        </div>
                        <div className='footer-menu-item2'>
                            <img src={mailIcon} alt='SGHS' height={50} style={{ paddingRight: 10 }} />

                            <div >
                                <div style={{ fontSize: '18px', color: '#A1238E', paddingBottom: '10px', paddingTop: '10px' }} >Mail</div>
                                <div  >saigrammarhs@gmail.com</div>
                            </div>
                        </div>
                        <div className='footer-menu-item2'>
                            <img src={locationIcon} alt='SGHS' height={50} style={{ paddingRight: 10 }} />
                            <div >
                                <div style={{ fontSize: '18px', color: '#A1238E', paddingBottom: '10px', paddingTop: '10px' }} >Address</div>
                                <div  > H.No.8-7-12/9/1,TNGO's Sai Colony, Padmashalipuram ,
                                    Kattedan,HYD, Telangana 500077</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='footer__item'>
                    <div className='footer__item_head'>
                        <img src={arrow} alt='SGHS' height={50} style={{ paddingRight: 10 }} />
                        <div>
                            <div className='footer-menu-title'><h4>Quick Links</h4></div>
                            <Menu text vertical className='footer-menu'>
                                <Menu.Item
                                    className='footer-menu-item'
                                    name={TabNames.HOME}
                                    active={props.activeItem === TabNames.HOME}
                                    onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, data: MenuItemProps) => {
                                        props.handleItemClick(event, data);
                                        scrollToTop();
                                    }}
                                    as={Link}
                                    to='/'
                                />
                                <Menu.Item
                                    className='footer-menu-item'
                                    name={TabNames.CONTACT}
                                    active={props.activeItem === TabNames.CONTACT}
                                    onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, data: MenuItemProps) => {
                                        props.handleItemClick(event, data);
                                        scrollToTop();
                                    }}
                                    as={Link}
                                    to='/contact'
                                />
                                <Menu.Item
                                    className='footer-menu-item'
                                    name={TabNames.ABOUT_US}
                                    active={props.activeItem === TabNames.ABOUT_US}
                                    onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, data: MenuItemProps) => {
                                        props.handleItemClick(event, data);
                                        scrollToTop();
                                    }}
                                    as={Link}
                                    to='/about-us'
                                />
                                <Menu.Item
                                    className='footer-menu-item'
                                    name={TabNames.GALLERY}
                                    active={props.activeItem === TabNames.GALLERY}
                                    onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, data: MenuItemProps) => {
                                        props.handleItemClick(event, data);
                                        scrollToTop();
                                    }}
                                    as={Link}
                                    to='/gallery'
                                />
                                <Menu.Item
                                    className='footer-menu-item'
                                    name={TabNames.DEMO}
                                    active={props.activeItem === TabNames.DEMO}
                                    onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, data: MenuItemProps) => {
                                        props.handleItemClick(event, data);
                                        scrollToTop();
                                    }}
                                    as={Link}
                                    to='/demo'
                                />
                            </Menu>
                        </div>
                    </div>
                </div>

                <div className='footer__item'>

                    <div className='footer__item_head1'>
                        <img src={clockIcon} alt='SGHS' height={50} style={{ paddingRight: 10 }} />
                        <div>
                            <div className='footer-menu-title'><h4>School Timings</h4></div>
                            <Menu text vertical className='footer-menu1'>
                                <div className='footer-text'  >
                                    {/* Nursery & UKG – 8:30 AM to 12:35 PM <br></br> */}
                                    Nursery to UKG – 8:45 AM to 3:00 PM<br></br>
                                    I Class to X Class– 8:45 AM to 5:00 PM<br></br>
                                    Working Days – Monday to Saturday</div>
                            </Menu>
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', marginTop: '20px' }}>
                        <a href="https://www.facebook.com/saigrammarhighschool" target="_blank"><img src={facbookIcon} alt='SGHS' height={50} style={{ paddingRight: 20 }} /></a>
                        <a href="https://api.whatsapp.com/send?phone=9000284777" target="_blank"><img src={whatsappIcon} alt='SGHS' height={50} style={{ paddingRight: 20 }} /></a>
                        <a href="https://www.instagram.com/saigrammarhighschool?igsh=MTQybDN4dGI0bTBj" target="_blank"><img src={phoneIcon} alt='SGHS' height={50} style={{ paddingRight: 20 }} /></a>
                    </div>
                </div>
            </div>
            <div className='footer__item2'>

                <a href="https://www.quinterrasoft.com/" target="_blank">
                    <div className='footer__pvt2'> All Rights Reserved. | Developed by Quinterra Software Solutions Pvt. Ltd.</div></a>

            </div>
        </footer>
    )
}
