import React, {  } from 'react'
import './Gallery.css'
import { Header,  } from 'semantic-ui-react'
import { GalleryTab } from './GalleryTab'
import bgImage2 from "../../images/BG2.png";
import BApic1 from "../../images/beyondAcademics/BApic1.png";
// import BApic1 from "../../images/KA2.jpg";
import GIcon1 from "../../images/G2.png";
import GI2 from "../../images/G.png";
import GI3 from "../../images/G3.png";
export const Gallery = () => {
  return (
    <div className='mobile' style={{
      // backgroundColor: 'rgba(70, 61, 163, 1)'
    }}>
     <div
        className='mobile__top3'
        style={{ backgroundImage: `url(${bgImage2})`, backgroundSize: 'cover', background: 'cover' }}
      >
          <img src={BApic1} alt="" className="AcPic2" />
          <img src={GI2} alt="" className="icon2" />
          <img src={GI3} alt="" className="icon3" /> 
         
        <Header as={'h1'} size='huge'>
      Gallery
        </Header>
        <img src={GIcon1} alt="" className="icon1" />
        {/* */}
      </div>
      <div className='mobile__bottom3'>
        <GalleryTab/>
      </div>
      
    </div>
  )
}